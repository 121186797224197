<script>
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { carouselJs } from "./carousel.js"
export default {
  name: "index",
  data(){
    return{
      apiUrl:{
        list: '/api/setting/getSetting',
        update: '/api/setting/saveOrUpdate',
      },
      dateDay: null,
      dateYear: null,
      timer: null,
      form:{
        inspector: null,
        inspectionUnit: null,
        contactNumber: null,
        checkTime: null,
        isCheckQrCode: null,
        autoUpload: false,
      },
      open: false,
      carouselList: carouselJs(),
      initialIndex: 0,

      // 校验指定字段
      rules: [
        'inspector',
        'inspectionUnit',
        'contactNumber',
        'checkTime',
      ],
      pickerOptions: {
        disabledDate(time) {
          // 禁止选择今天的日期及之前的所有日期
          return time.getTime() > Date.now();
        },
      },
      isManager: null,
    }
  },
  mounted() {
    this.setTime();
    this.getList();
  },
  methods:{
    setTime(){
      this.timer = setInterval(() => {
        const date = dayjs(new Date());
        this.dateDay = date.format("HH:mm:ss");
        this.dateYear = date.format("YYYY年MM月DD日");
      }, 1000);
    },
    getList(){
      this.postApiConfig(this.apiUrl.list).then((res)=>{
        console.log("res",res)
        // this.form = res.data.detectionSetting
        // this.isManager = res.data.isManager

        if (res.data.detectionSetting) {
          this.form = res.data.detectionSetting;
        }

        // 同样检查 isManager 的存在性
        if ('isManager' in res.data) {
          this.isManager = res.data.isManager;
        }
      }).catch(()=>{

      })
    },
    openUsingHelp(){
      this.open = true;
      this.$nextTick(()=>{
        this.initialIndex = 0;
        this.$refs.carousel.setActiveItem(this.initialIndex)
      })
    },
    defaultStep() {
      this.open = false;
    },
    upStep() {
      this.initialIndex--;
      this.$refs.carousel.setActiveItem(this.initialIndex)
    },
    nextStep() {
      this.initialIndex++;
      this.$refs.carousel.setActiveItem(this.initialIndex)
    },
    clickLi(index) {
      this.initialIndex = index
      this.$refs.carousel.setActiveItem(index)
    },
    handleClose(){
      this.open = false;
    },
    validateFormFields(form) {
      const query = {
        inspector: '检测人员不能为空！',
        inspectionUnit: '检测单位不能为空！',
        contactNumber: '联系电话不能为空！',
        checkTime: '检测时间不能为空！'
      };

      // 用于记录是否有字段为空
      let hasEmptyField = false;

      // 遍历并验证每个字段
      for (const key in form) {
        if (query.hasOwnProperty(key) && !form[key]) {
          this.$message.error(query[key]);
          hasEmptyField = true; // 发现空字段，标记为true
        }
      }

      return !hasEmptyField; // 如果没有空字段，则返回true
    },
    // 保存
    submitForm(){
      // 校验参数有无填写
      if (!this.validateFormFields(this.form)) {
        return false
      }
      const PHONE_REGEX = /^1[3-9]\d{9}$/;
      if (!PHONE_REGEX.test(this.form.contactNumber)) {
        this.$message.error("请输入正确手机号");
        return false;
      }
      this.$modal.loading("正在请求数据，请稍后...");
      delete this.form.createTime
      this.postApiConfig(this.apiUrl.update,this.form).then((res)=>{
        this.$modal.closeLoading();
        if(res.code == 200){
          this.getList();
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      }).catch(()=>{
        this.$modal.closeLoading();
      })
    },
    logout(){
      this.$confirm('此操作将检测系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        Cookies.remove("username");
        Cookies.remove("password");
        Cookies.remove("rememberMe");
        Cookies.remove("token");
        this.$router.push({path: '/login'})
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    changeTime(){
      this.$forceUpdate();
    }
  },
  beforeMount() {
    clearInterval(this.timer)
    this.timer = null;
  }
}
</script>

<template>
  <div class="system-page">
    <div class="system-title">
      <span>检测设置</span>
      <div class="usingHelp" @click="openUsingHelp">
        <span>使用帮助</span>
      </div>
    </div>
    <div class="system-content">
      <div class="system-content-item">
        <div class="system-content-item-left">
          <span>检测人员</span>
        </div>
        <div class="system-content-item-right right_input">
          <el-input v-model="form.inspector" placeholder="请输入检测人员" clearable></el-input>
        </div>
      </div>
      <div class="system-content-item">
        <div class="system-content-item-left">
          <span>检测单位</span>
        </div>
        <div class="system-content-item-right right_input">
          <el-input v-model="form.inspectionUnit" placeholder="请输入检测单位" clearable></el-input>
        </div>
      </div>
      <div class="system-content-item">
        <div class="system-content-item-left">
          <span>联系电话</span>
        </div>
        <div class="system-content-item-right right_input">
          <el-input v-model="form.contactNumber" placeholder="请输入联系电话" clearable></el-input>
        </div>
      </div>
<!--      <div class="system-content-item">-->
<!--        <div class="system-content-item-left">-->
<!--          <span>上传地址</span>-->
<!--        </div>-->
<!--        <div class="system-content-item-right" style="padding-left: 15px;">-->
<!--          <span>{{ form.uploadAddress }}</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="system-content-item">
        <div class="system-content-item-left">
          <span>系统时间</span>
        </div>
        <div class="system-content-item-right-date">
          <div class="n-y-r">
            <el-date-picker
                v-model="form.checkTime"
                type="date"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                @change="changeTime"
                :picker-options="pickerOptions">
            </el-date-picker>
          </div>
          <div class="h-m-s">
            <span><i class="el-icon-time"></i></span>
            <span>{{ dateDay }}</span>
          </div>
        </div>
      </div>
      <div class="system-content-item">
        <div class="system-content-item-left">
          <span>是否检测二维码</span>
        </div>
        <div class="system-content-item-right" style="padding-left: 15px;">
          <el-checkbox v-model="form.isCheckQrCode" :disabled="isManager == 0"></el-checkbox>
        </div>
      </div>
      <div class="system-content-item">
        <div class="system-content-item-left">
          <span>是否自动上传</span>
        </div>
        <div class="system-content-item-right" style="padding-left: 15px;">
          <el-checkbox v-model="form.autoUpload"></el-checkbox>
        </div>
      </div>
    </div>
    <div class="system-content-btn">
      <div class="exit" @click="logout">
        <span>退出登录</span>
      </div>
      <div class="preserve" @click="submitForm">
        <span>保存</span>
      </div>
    </div>
    <!-- 使用帮助 -->
    <div>
      <el-dialog title="欢迎使用胶体金检测" :visible.sync="open" width="900px" :before-close="handleClose">
        <div class="guidanceMain">
          <el-carousel ref="carousel" height="480px" :autoplay="false" indicator-position="none" arrow="never" :initial-index="initialIndex">
            <el-carousel-item v-for="(item,index) in carouselList" :key="index">
              <div class="itemBox">
                <div class="imgBox">
                  <img :src="item.imgUrl" alt="">
                </div>
                <div class="textBox">
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div class="text">
                    {{ item.text }}
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div class="tag">
            <div v-for="(item,index) in carouselList.length" :key="index" class="tagLi"
                 :class="initialIndex == index ? 'new':'default'" @click="clickLi(index)"></div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" plain @click="upStep" v-if="initialIndex > 0">上 一 步</el-button>
          <el-button @click="nextStep" v-if="carouselList.length -1  != initialIndex">下 一 步</el-button>
          <el-button type="primary" @click="defaultStep" v-else>我 知 道 了</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./system.css";
.system-page{
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px 0;

  .system-title{
    width: 100%;
    font-weight: bold;
    font-size: 28px;
    color: #333333;
    text-align: center;
    position: relative;

    .usingHelp{
      position: absolute;
      top: 0;
      right: 0;
      width: 82px;
      height: 30px;
      background-color: #FFFFFF;
      border-radius: 5px;
      border: 1px solid #0062C4;
      font-weight: 400;
      font-size: 16px;
      color: #0062C4;
      text-align: center;
      line-height: 30px;
      cursor:pointer;
    }
  }

  .system-content{
    width: 70%;

    .system-content-item{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #dcdfe6;

      .system-content-item-left{
        font-weight: 400;
        font-size: 18px;
        color: #333333;
      }
      .system-content-item-right{
        width: 344px;
        height: 40px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        color: #333333;

        input{
          border: none;
          outline: none;
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: left;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
        }
      }
      .system-content-item-right-date{
        width: 344px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        color: #333333;

        .n-y-r{
          width: 60%;
        }

        .h-m-s{
          flex: 1;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0 10px;
        }
      }
    }
  }

  .system-content-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 23.75rem  /* 380/16 */;
    cursor:pointer;

    .exit{
      width: 183px;
      height: 50px;
      background-color: #ffffff;
      border-radius: 10px;
      font-weight: 400;
      font-size: 20px;
      color: #60627f;
      text-align: center;
      line-height: 50px;
      border: 1px solid #dcdfe6;
    }
    .preserve{
      width: 183px;
      height: 50px;
      background-color: #0062C4;
      border-radius: 10px;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      text-align: center;
      line-height: 50px;
    }
  }

  .guidanceMain {
    width: 100%;
    .itemBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .imgBox {
        width: 100%;
        img {
          width: 100%;
        }
      }

      .textBox {
        flex: 1;
        width: 100%;

        .name {
          margin: 10px 0;
          color: #333333;
          font-size: 20px;
          font-weight: bolder;
        }

        .text {
          color: #999999;
          font-size: 18px;
          /*font-weight: bolder;*/
        }
      }

    }
  }

  .tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tagLi {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      margin-right: 6px;
    }
    .default {
      background-color: #CCE0F3;
    }
    .new {
      background-color: #0062c4;
    }
  }
}
</style>
