<script>
import axios from "axios";

export default {
  name: "detection",
  props:{
    setProjectName:{
      type: Object,
      default: ()=>{}
    },
    setCardIndex:{
      type: Number,
      default: 1,
    },
  },
  watch:{
    setProjectName:{
      handler(val){
        this.resetForm();
        this.$forceUpdate()
        if(Object.keys(val).length > 0){
          this.projectNameForm = val
          this.form.projectType = val.projectType
          this.form.name = val.projectName
          this.form.cardId = val.id;
          this.form.cardType = val.cardType;
          this.form.settingValue = val.settingValue;
          let detectionMethod = val.detectionMethod.split(",");
          this.testMethodList = [];
          detectionMethod.forEach((item) =>{
            if(item == 1){
              this.testMethodList.push({
                value: 1,
                label: "比色法"
              })
            }else if(item == 2){
              this.testMethodList.push({
                value: 2,
                label: "消线法"
              })
            }
          })
          this.form.detectionMethod = Number(detectionMethod[0]);
          const matchedItem = this.settingTypeList.find(item => item.projectType === val.projectType);
          if (matchedItem) {
            this.settingType = matchedItem.list;
          }
          // 多联卡项目
          if(val.cardType > 1){
            this.arbitrarily = false;
            this.multipleState = false;
            // 判断是否为任意多联卡
            if(val.detectionProjects){
              this.cardResult = [...val.detectionProjects];
            }else{
              let obj = {
                id: '',
                machineId: val.machineId,
                projectName: '',
                projectType: val.projectType,
                settingValue: val.settingValue,
                cardType: '',
                code: null,
                detectionMethod: val.detectionMethod,
                sort: null,
                tripleProject: null,
                authType: val.authType,
                projectList: null,
                methods: null,
                sampleList: null,
                detectionProjects: null,
                inCardTypes: null,
                equipmentSn: null,
                project: val.project,
                specify: true,
              }
              this.cardResult = this.createFilledArray(val.cardType,obj);
              // 遍历生成的数组，为每个对象添加序号属性
              for (let i = 0; i < this.cardResult.length; i++) {
                // 使用解构赋值和对象展开语法来避免直接修改原始对象
                this.cardResult[i] = { ...this.cardResult[i], sortNum: i + 1 };
              }
              this.arbitrarily = true;
              // console.log("我要的结果",this.cardResult)
              // console.log(JSON.stringify(this.cardResult))
              this.testItemShow = !this.testItemShow;
              this.multipleState = true;
              this.closeBtnStatus = true;
            }
          }else{
            this.cardResult = [{
              id: val.id,
              projectName: val.projectName,
              project: val.project,
              projectType: val.projectType,
              settingValue: val.settingValue,
              machineId: val.machineId,
              detectionMethod: val.detectionMethod,
              code: val.code,
              cardType: val.cardType,
            }]
          }
        }
      },
      immediate: true,
    },
    setCardIndex:{
      handler(newVal){
        let arr = {}
        this.cardResult = new Array(newVal).fill(arr);
      },
      immediate: true,
    }
  },
  data() {
    return{
      apiUrl:{
        sampleList: '/api/sample/list',
        unit: '/api/history/findUnit',
        system: '/api/setting/getSetting',
        qrcode: '/check/qrcode',
        check: '/check/photo',
        newCheck: '/api/ct/check', // 新检测接口
        history: '/api/history/saveOrUpdate',
        newHistory: '/api/bxs/history/upload', // 新上传检测记录接口
        add: '/api/sample/saveOrUpdate',
        edit: '',
        upload: '/api/bxs/colloidal/upload', // 新上传省平台接口
        update: '/api/bxs/colloidal/isUpload/',
        dict: '/api/bxs/dict/',
        list: '/api/project/list',
        card: '/api/bxs/dict/',
      },
      // 视频加载显示
      videoShow:false,
      videoElement: null,
      // 图片显示
      imgShow: false,
      // 截取的图片资源
      imgUrl: '',
      imageDataUrlArr: [],
      // 检测项目
      projectNameForm:{},
      // 表单
      form:{},
      // 样品编号
      sampleNumbering: '',
      // 样品操作
      sampleList:[],
      filterSampleList: [],
      sampleShow:false,
      sampleIndex: '',
      sampleSearch:'',
      // 检测方法
      testMethodList:[],
      // 样品类型
      settingTypeList:[
        {
          projectType: 1,
          list:["畜类", "畜肉", "禽类", "禽肉", "禽蛋"]
        },
        {
          projectType: 2,
          list:["水果", "茶叶", "食用菌", "蔬菜"]
        }
      ],
      settingType: [],
      // 系统设置
      systemForm: {},
      // 检测结果
      resultForm: {},
      // 上传历史的结果
      uploadResult: {},
      // 上传按钮状态
      uploadButtonStatus: false,
      //
      sampleAddShow: false,
      sampleForm: {},
      sampleRules:{
        type: [
          {required: true, message: "请选择类型名称", trigger: 'change'},
        ],
        sampleName: [
          {required: true, message: "请输入样品名称", trigger: 'blur'},
        ],
        limitValue: [
          {required: true, message: "请输入限定值", trigger: 'blur'},
        ],
      },
      editShow: false,
      // 截图次数
      cutNum: 5,
      // 检测卡结果
      cardResult:[],
      // 检测时间
      checkTime: '',
      // 整合上传省平台的数据
      uploadForm: {},
      // 字典
      beastSamples: [],
      longitude: null, // 经度
      latitude: null, // 纬度
      // 显示详细数据
      displayDetails: false,
      drawerTitle: '',
      drawerShow: false,
      drawerForm: {
        cargoSource: '',
        cargoQuantity: '',
        cargoSampleQuantity: '',
        samplerUser: '',
        cargoOwner: '',
        reagentProduction: '',
        remark: '',
      },
      drawerRules:{
        cargoSource: [
          {required: true, message: "请输入生猪来源", trigger: 'blur'},
        ],
        cargoQuantity: [
          {required: true, message: "请输入生猪头数", trigger: 'blur'},
        ],
        cargoSampleQuantity: [
          {required: true, message: "请输入抽样头数", trigger: 'blur'},
        ],
        samplerUser: [
          {required: true, message: "请输入抽样人员", trigger: 'blur'},
        ],
        cargoOwner: [
          {required: true, message: "请输入畜（货）主", trigger: 'blur'},
        ],
        reagentProduction: [
          {required: true, message: "请输入试剂生产企业", trigger: 'blur'},
        ],
        remark: [
          {required: true, message: "请输入备注", trigger: 'blur'},
        ],
      },
      // 详情填写状态
      detailedStatus: false,
      // 任意多联卡
      arbitrarily: false,
      testItemShow: false,
      testItemList: [],
      testItemListAll: [],
      testItemIndex: null,
      multipleArr: [],
      multipleState: false,
      sortInfo: {},
      findValue: {},
      testItemSearch:'',
      // 取消按钮状态
      closeBtnStatus: false,
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.getCompetence();
      this.getList();
      this.getSystem();
      this.getDict()
    })
  },
  methods:{
    async getList() {
      try {
        // 并行执行获取 cardType 和列表数据，以提高效率
        const [cardTypeRes, listRes] = await Promise.all([
          this.getApiConfig(this.apiUrl.card, 'reagent_card_type'),
          this.postApiConfig(this.apiUrl.list, { projectType: 1 }),
        ]);

        // 分配响应数据
        let cardType = cardTypeRes.data;

        // 确保 cardType 已加载完成再进行过滤操作，这里假设 cardType 总是会有数据
        this.testItemList = listRes.data.filter(item =>
            parseInt(item.cardType) === parseInt(cardType[0].dictValue)
        );
        this.testItemList.forEach(item => {
          item.disabledStr = false;
          item.choiceState = false;
        });
        this.testItemListAll = this.testItemList
        console.log("testItemList",this.testItemList)
      } catch (error) {
        // 错误处理，可以根据需要自定义
        console.error('获取列表数据时发生错误:', error);
      }
    },
    async getLocation() {
      console.log(navigator.geolocation)
      if (navigator.geolocation) {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          console.log("经度:", this.longitude, "纬度:", this.latitude)
          // 天地图
          axios.get('http://api.tianditu.gov.cn/geocoder?postStr={\'lon\':' + this.longitude + ',\'lat\':' + this.latitude + ',\'ver\':1}&type=geocode&tk=af372b2b26a6a9894bdd1661cbead676').then((response) => {
            console.log('天地图', response)
            let data = response.data.result.addressComponent
            console.log(data)
          })
        } catch (error) {
          console.error("获取地理位置失败:", error);
          this.showError(error.message);
        }
      } else {
        console.error("浏览器不支持地理定位。");
      }
    },
    showError(message) {
      alert(message);
    },
    // 获取字典
    getDict(){
      this.getApiConfig(this.apiUrl.dict,'beastSamples').then((res)=>{
        console.log("res",res)
        this.beastSamples = res.data
      })
    },
    // 检测摄像头
    getCompetence() {
      this.$modal.loading("摄像头检测中...");
      try {
        // 兼容性处理：为navigator.mediaDevices添加getUserMedia方法
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices = Object.assign({}, navigator.mediaDevices, {
            getUserMedia: (constraints) =>
                new Promise((resolve, reject) => {
                  const legacyGetUserMedia =
                      navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
                  if (!legacyGetUserMedia) {
                    reject(new Error("getUserMedia is not implemented in this browser"));
                  } else {
                    legacyGetUserMedia.call(navigator, constraints, resolve, reject);
                  }
                }),
          });
        }

        const videoElement = document.getElementById("video");
        const constraints = { video: true, audio: false };

        // 获取视频流并处理
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
              videoElement.srcObject = stream;

              // 播放视频
              videoElement.onloadedmetadata = () => videoElement.play();

              // 获取并打印摄像头列表
              navigator.mediaDevices.enumerateDevices()
                  .then(devices => devices.filter(device => device.kind === "videoinput"))
                  .then(cameras => console.log(cameras))
                  .catch(error => console.error('Error getting camera list:', error));
              this.videoShow = false;
              this.$modal.closeLoading();
            })
            .catch((error) => {
              this.$modal.closeLoading();
              this.$message.error("摄像头未检测到，请重新检测")
              this.videoShow = true
              console.error(`${error.name}: ${error.message}`);
            });
      } catch (error) {
        this.videoShow = true;
        this.$modal.closeLoading();
      }
    },
    // 选择检测方法
    choiceDetectionMethod(item){
      this.form.detectionMethod = item.value
    },
    // 重新检测摄像头
    retestCamera(){
      this.getCompetence();
    },
    stopStream() {
      let tracks = this.videoElement.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    },
    // 获取系统配置信息
    getSystem(){
      this.postApiConfig(this.apiUrl.system).then((res)=>{
        let data = res.data.detectionSetting
        data.originalTime = data.checkTime
        data.checkTime = data.checkTime.replace(/-/g, '');
        this.systemForm = data
      })
    },
    // 开始检测
    // 开始检测
    detectionBtn() {
      console.log("this.videoShow", this.videoShow);

      if (this.videoShow) {
        this.$message.error("摄像头未检测到，请重新检测");
        return false;
      }

      // 验证表单字段
      if (!this.validateFormFields(this.form)) {
        console.log("表单验证未通过，操作终止。");
        return false;
      }
      // 使用 trim() 方法去除首尾空白，然后检查长度
      this.form.unit = this.form.unit.trim();
      this.$forceUpdate();
      // 再检查长度
      if (this.form.unit.length < 3) {
        this.$message.error('受检单位至少输入3个或以上字符');
        return false;
      }
      // 校验任意多联卡
      if(this.arbitrarily){
        const emptyIdIndex = this.findEmptyIdIndex(this.cardResult);
        if (emptyIdIndex !== -1) {
          console.log(`找到一个id为空的对象，位于索引 ${emptyIdIndex}`);
          this.$message.error(`请填写左 ${emptyIdIndex + 1} 槽的检测项目`);
          return false
        }
      }

      // 显示加载提示
      this.$modal.loading("正在检测中，请稍后...");
      // 这里先加载一张显示在页面
      const video = this.$refs.videoPlayer;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      // 设置canvas尺寸与视频尺寸一致
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // 将视频当前帧绘制到canvas上
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // 将canvas内容转换为dataURL
      const imageDataUrl = canvas.toDataURL('image/png');

      // 显示截图
      this.displaySnapshot(imageDataUrl.split(",")[1]);

      this.imageDataUrlArr = [];
      let currentIndex = 0;

      // 定义一个递归函数来逐帧捕获并延迟插入数组
      const captureAndDelay = () => {
        if (currentIndex >= this.cutNum) {
          // 所有帧处理完毕后调用校验参数方法
          this.changeResult(this.imageDataUrlArr[this.imageDataUrlArr.length - 1]);
          console.log("imageDataUrlArr", this.imageDataUrlArr);
          return;
        }

        const video = this.$refs.videoPlayer;
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');

        // 设置canvas尺寸与视频尺寸一致
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // 将视频当前帧绘制到canvas上
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // 将canvas内容转换为dataURL
        const imageDataUrl = canvas.toDataURL('image/png');

        // 将处理后的dataURL推入数组
        this.imageDataUrlArr.push(imageDataUrl.split(",")[1]);

        // 递归调用自身，实现每帧之间的延迟
        setTimeout(() => {
          currentIndex++;
          captureAndDelay();
        }, 500);
      };

      // 启动帧捕获及延迟插入的递归过程
      captureAndDelay();
    },
    // 显示截图
    displaySnapshot(imageDataUrl) {
      this.imgShow = true;
      this.imgUrl = imageDataUrl;
    },
    resetForm(){
      this.form = {
        projectType: '',
        name:'',
        cardId:'',
        cardType:'',
        settingValue:'',
        detectionMethod:'',
        sampleName:'',
        maxValue:'',
        sampleType:'',
        sampleNumbering:'',
        unit:'',
      };
      this.uploadButtonStatus = false;
      this.testMethodList = [];
      this.sampleIndex = '';
      this.displayDetails = null;
      this.drawerForm = {
        cargoSource: '',
        cargoQuantity: '',
        cargoSampleQuantity: '',
        samplerUser: '',
        cargoOwner: '',
        reagentProduction: '',
        remark: '',
      };
      this.testItemList.forEach((item)=>{
        item.disabledStr = false
      });
      this.sortInfo = {};
      this.testItemShow = false;
      this.multipleState = false;
      this.closeBtnStatus = false;
    },
    // 选择样品
    showSampleList(){
      this.sampleList = []
      if(!this.projectNameForm.id){
        this.$message.error("请选择检测项目")
        return false
      }
      this.postApiConfig(this.apiUrl.sampleList,{projectId: this.projectNameForm.id}).then((res)=>{
        this.sampleShow = true;
        this.sampleList = res.data
        this.filterSampleList = res.data
        this.drawerForm = {
          cargoSource: '',
          cargoQuantity: '',
          cargoSampleQuantity: '',
          samplerUser: '',
          cargoOwner: '',
          reagentProduction: '',
          remark: '',
        };
        this.displayDetails = false;
        this.drawerShow = false;
        console.log("this.displayDetails",this.displayDetails)
      })
    },
    // 关闭样品列表
    handleClose(){
      this.sampleList = []
      this.sampleShow = false;
      this.editShow = false;
      this.sampleIndex = ''
    },
    // 选择样品
    choiceSample(index,item){
      this.sampleIndex = index;
      console.log("item",item)
      this.editShow = false;
      this.drawerForm = {
        cargoSource: '',
        cargoQuantity: '',
        cargoSampleQuantity: '',
        samplerUser: '',
        cargoOwner: '',
        reagentProduction: '',
        remark: '',
      };
      if(item.machineId != 0){
        this.editShow = true
        this.sampleForm = Object.assign({},item)
      }
    },
    getTime() {
      const now = new Date();
      const padZero = (num) => (num < 10 ? '0' : '') + num; // 简化的补零函数
      return `${now.getFullYear()}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}${padZero(now.getHours())}${padZero(now.getMinutes())}${padZero(now.getSeconds())}`;
    },
    getHfm() {
      const now = new Date();
      const padZero = (num) => num < 10 ? '0' + num : num.toString(); // 统一的补零函数

      // 使用padZero函数简化小时、分钟、秒的格式化
      const formattedHours = padZero(now.getHours());
      const formattedMinutes = padZero(now.getMinutes());
      const formattedSeconds = padZero(now.getSeconds());

      // 生成随机数并转换为字符串
      const randomNum = padZero(Math.floor(Math.random() * 90) + 10);

      // 返回组合后的字符串
      return `${formattedHours}${formattedMinutes}${formattedSeconds}${randomNum}`;
    },
    // 确定样品
    confirmSample(){
      if(this.sampleIndex === undefined || this.sampleIndex === null || this.sampleIndex === ''){
        this.$message.error("请选择样品")
        return false;
      }
      console.log(this.sampleList[this.sampleIndex])
      this.form.sampleName = this.sampleList[this.sampleIndex].sampleName;
      this.form.maxValue = this.sampleList[this.sampleIndex].limitValue;
      this.form.sampleType = this.sampleList[this.sampleIndex].type;
      this.form.sampleId = this.sampleList[this.sampleIndex].id;
      // 选择样品名称更新样品编号
      const hfm = this.getHfm()
      this.form.sampleNumbering = this.systemForm.checkTime ? this.systemForm.checkTime + hfm : this.getTime();
      this.uploadButtonStatus = false
      this.sampleShow = false;
      this.cardResult.forEach((item)=>{
        item.afterRes = '';
        item.decideOutcome = '';
      })
    },
    // 受检单位选择
    querySearch(queryString, cb) {
      // if(queryString.length < 3){
      //   this.$message.error('受检单位至少输入3个字符');
      //   return false;
      // }
      this.postApiConfig(this.apiUrl.unit, { unit: queryString }).then(response => {
        console.log("模糊搜索", response);
        const results = response.data
            .filter(item => item.includes(queryString))
            .map(item => ({ value: item }));
        cb(results);
      });
    },
    handleSelect(item) {
      this.form.unit = item.value
    },
    // 检测方法(单选按钮)
    changeRadio(){
      this.$forceUpdate()
    },
    // 校验参数有无填写
    validateFormFields(form) {
      const requiredFields = {
        name: '项目名称',
        sampleName: '样品名称',
        unit: '受检单位',
      };
      // 遍历并验证每个必填字段，一旦发现空字段立即提示并终止循环
      for (const key in requiredFields) {
        if (!form[key]) {
          this.$message.error(`${requiredFields[key]}不能为空！`);
          return false; // 发现空字段，立即返回false表示验证未通过，并停止后续验证
        }
      }
      return true; // 没有发现空字段，表示所有必填项都已填写，验证通过
    },
    // 检测结果
    async changeResult(imageDataUrl) {
      console.log("开始处理结果");
      try {
        // 检测操作
        await this.performDetectionOperations(imageDataUrl);
      } catch (error) {
        this.$modal.closeLoading();
        console.error("处理过程中发生错误:", error);
      }
    },
    // 执行检测操作
    async performDetectionOperations(imageDataUrl) {
      // 检测二维码
      if (this.systemForm.isCheckQrCode) {
        const qrCodeResponse = await this.detectQRCode(imageDataUrl);
        console.log("二维码检测结果",qrCodeResponse)
        if(qrCodeResponse.code == 200){
          this.$forceUpdate()
          this.form.qrKey = qrCodeResponse.data.qrKey;
        }else{
          this.$modal.closeLoading();
          this.$message.error(qrCodeResponse.msg);
          return false
        }
      }
      // 检测试剂卡结果
      await this.reagentCard();
    },
    // 检测二维码
    detectQRCode(){
      return this.postApiConfig(this.apiUrl.qrcode,{base64: this.imgUrl})
    },
    // 检测试剂卡结果
    reagentCard(){
      // 参数
      let data = {
        projectId: this.form.cardId,
        detectionMethod: this.form.detectionMethod,
        images: this.imageDataUrlArr,
        qrKey: this.form.qrKey,
      }
      // 任意多联卡
      if(this.arbitrarily){
        data.tripleProject = this.cardResult.map(item => item.id).toString()
      }else{
        data.tripleProject = this.projectNameForm.cardType > 1 ? this.projectNameForm.tripleProject: ''
      }

      console.log("检测参数",data)
      this.postApiConfig(this.apiUrl.newCheck,data).then((res)=>{
        console.log(res)
        this.$forceUpdate()
        if(res.code == 200){
          console.log("检测结果",res.data)
          let data = res.data
          const mergedData = this.cardResult.map(itemX => {
            const match = data.find(itemY => itemY.projectId === itemX.id);
            return match ? { ...itemX, ...match } : itemX;
          });
          this.cardResult = mergedData;
          console.log("cardResult",this.cardResult)
          // 延迟500毫秒执行
          setTimeout(()=>{
            // this.uploadHistory(this.cardResult)
            // 用于后期上传
            this.sampleNumbering = this.form.sampleNumbering;
            this.uploadForm = Object.assign({},this.form)
            this.checkTime = this.systemForm.originalTime + ' ' + this.getCurrentTimeString();
            this.uploadProvince()
          },500)
        }else{
          this.$modal.closeLoading();
          // 更新样品编号
          const hfm = this.getHfm()
          this.form.sampleNumbering = this.systemForm.checkTime ? this.systemForm.checkTime + hfm : this.getTime();
          this.uploadButtonStatus = false;
          this.updateCardProject()
          this.$message.error(res.msg);
        }
      }).catch(()=>{
        this.$modal.closeLoading();
      })
    },
    // 检测结果上传到历史记录(废弃，不使用)
    uploadHistory(data){
      this.$modal.loading("正在上传到历史检测记录中，请稍后...");
      this.checkTime = this.systemForm.originalTime + ' ' + this.getCurrentTimeString();
      let obj = {
        qrKey: this.form.qrKey, //二维码识别内容(新)
        projectId: this.form.cardId,
        projectName: this.form.name, //项目名称
        type: 2, // 1：app，2：pc
        sampleId: this.form.sampleId, //样品名称
        sampleName: this.form.sampleName, //样品名称
        sampleNumber: this.form.sampleNumbering, //样品编号
        sampleType: this.form.sampleType, //样品类型
        detectionMethod: this.form.detectionMethod, //检测方法
        checkTime: this.checkTime, //检测时间
        projectList:[],
      }

      let projectList = data.map(item => ({
        ocrImage: item.ocrImage,
        grayImage: item.grayImage,
        cValue: this.formattedBeforeRes(item.c),
        tValue: this.formattedBeforeRes(item.t),
        projectName: item.projectName,
        projectId: item.projectId,
        result: item.decideOutcome,
        resultValue: this.formattedBeforeRes(item.afterRes),
        serialNumber: this.form.sampleNumbering
      }));
      obj.projectList = projectList;
      console.log("检测结果上传到历史记录",obj)

      this.postApiConfig(this.apiUrl.newHistory,obj).then((res)=>{
        console.log("检测结果上传",res)
        this.$modal.closeLoading();
        this.$forceUpdate();
        // 更新样品编号
        const hfm = this.getHfm()
        this.form.sampleNumbering = this.systemForm.checkTime ? this.systemForm.checkTime + hfm : this.getTime();
        if(res.code == 200){
          // this.$message.success("检测结果上传成功");
          this.uploadResult = {
            historyId: res.data
          };
          // 上传省平台
          if(this.systemForm.autoUpload){
            setTimeout(()=>{
              // 开启了自动上传执行
              this.uploadProvince()
            },500)
          }else{
            // 开启手动上传
            this.uploadButtonStatus = true;
          }
        }else{
          this.uploadButtonStatus = false;
        }
      }).catch(()=>{
        this.$modal.closeLoading();
      })
    },
    // 上传检测记录
    async uploadProvince() {
      try {
        // 显示加载提示
        // this.$modal.loading("正在上传到历史检测记录中，请稍后...");

        // 获取用户凭证及准备上传数据
        const uploadPayload = this.prepareUploadPayload();

        // 执行上传至省平台的请求
        const uploadResponse = await this.postApiConfig(this.apiUrl.upload, uploadPayload);
        this.uploadResult = uploadResponse.data;
        console.log("上传响应:", uploadResponse);

        // 关闭加载提示
        this.$modal.closeLoading();
        // 根据上传结果决定后续操作
        if (uploadResponse.code === 200) {
          // 更新样品编号
          const hfm = this.getHfm()
          this.form.sampleNumbering = this.systemForm.checkTime ? this.systemForm.checkTime + hfm : this.getTime();
          this.$message.success("检测完成")
          // 上传省平台
          if(this.systemForm.autoUpload){
            this.$modal.loading("正在上传至省平台，请稍候...");

            let index = this.cardResult.findIndex(item => item.decideOutcome === '无效');
            if(index > -1){
              this.$modal.closeLoading();
              this.$message.error("检测结果中包含无效试剂，不能上传结果，请重新检测");
              return false
            }

            // 开启了自动上传执行
            await this.updateHistoryRecord(uploadResponse.data.id);
            // 关闭加载提示
            this.$modal.closeLoading();
            // 更新样品编号
            const hfm = this.getHfm()
            this.form.sampleNumbering = this.systemForm.checkTime ? this.systemForm.checkTime + hfm : this.getTime();
            this.uploadButtonStatus = false;
            this.$message.success("上传至省平台成功")
          }else{
            // 开启手动上传
            this.uploadButtonStatus = true;
            return false
          }
        } else {
          this.$message.error(uploadResponse.msg)
          console.error("上传至省平台失败，响应码:", uploadResponse.code);
        }
      } catch (error) {
        this.$message.error('上传过程中发生错误')
        console.error("上传过程中发生错误:", error);
        // 确保任何错误情况下都关闭加载提示
        this.$modal.closeLoading();
      }
    },
    async manualUpload(){
      let index = this.cardResult.findIndex(item => item.decideOutcome === '无效');
      if(index > -1){
        this.$modal.closeLoading();
        this.$message.error("检测结果中包含无效试剂，不能上传结果，请重新检测");
        return false
      }
      await this.updateHistoryRecord(this.uploadResult.id);
      this.$message.success("上传至省平台成功")
      // 更新样品编号
      const hfm = this.getHfm()
      this.form.sampleNumbering = this.systemForm.checkTime ? this.systemForm.checkTime + hfm : this.getTime();
      this.uploadButtonStatus = false;
    },
    // 辅助函数
    prepareUploadPayload() {
      const batchSn = this.getTimeRandom();

      let projectList = this.cardResult.map(item => ({
        ocrImage: item.ocrImage,
        grayImage: item.grayImage,
        cValue: this.formattedBeforeRes(item.c),
        tValue: this.formattedBeforeRes(item.t),
        projectName: item.projectName,
        projectId: item.projectId,
        result: item.decideOutcome,
        resultValue: this.formattedBeforeRes(item.afterRes),
        serialNumber: this.sampleNumbering
      }));

      return {
        batchSn, // 检测批次
        qrKey: this.uploadForm.qrKey, // 二维码编号
        projectId: this.uploadForm.cardId, // 项目ID
        projectName: this.uploadForm.name, //项目名称
        sampleId: this.uploadForm.sampleId, //样品名称
        sampleName: this.uploadForm.sampleName, //样品名称
        sampleNumber: this.uploadForm.sampleNumbering, //样品编号
        sampleType: this.uploadForm.sampleType, //样品类型
        judgeName: '', // 判断名称
        detectionMethod: this.uploadForm.detectionMethod, // 检测方法 1：比色法，2：消线法
        checkTime: this.checkTime, // 检测时间
        projectList: projectList, // 检测数据详情
        inspectedUnit: this.uploadForm.unit, // 检测单位
        channel: 2, // 1：app，2：pc
        // 详细数据
        cargoSource: this.drawerForm.cargoSource, // 生猪来源
        cargoQuantity: this.drawerForm.cargoQuantity, // 生猪头数
        cargoSampleQuantity: this.drawerForm.cargoSampleQuantity, // 抽样头数
        samplerUser: this.drawerForm.samplerUser, // 抽样人员
        cargoOwner: this.drawerForm.cargoOwner, // 畜（货）主
        reagentProduction: this.drawerForm.reagentProduction, // 试剂生产企业
        remark: this.drawerForm.remark, // 备注
      };
    },
    // 更新历史记录的状态
    updateHistoryRecord(data) {
      return this.getApiConfig(this.apiUrl.update, data);
    },
    // 特定格式的随机时间字符串
    getTimeRandom() {
      const current = new Date();
      const formattedDate = `${current.getFullYear().toString().slice(-2)}${addZero(current.getMonth() + 1)}${addZero(current.getDate())}`;
      const randomNumber = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;
      const result = `BS_${formattedDate}${randomNumber}`;
      return result;
      function addZero(num) {
        return num < 10 ? '0' + num : num;
      }
    },
    // 样品搜索
    inputSample(){
      this.filterSampleList = this.sampleList.filter((e) => {
        return e.sampleName.indexOf(this.sampleSearch) != -1;
      });
    },
    // 添加样品
    addSample(){
      this.sampleForm = {};
      this.sampleAddShow = true;
    },
    // 打开样品选择
    openSample(){
      this.sampleAddShow = true;
      this.$nextTick(()=>{
        this.$refs['sampleForm'].resetFields();
        this.sampleForm = Object.assign({},this.filterSampleList[this.sampleIndex]);
      })
    },
    // 关闭样品选择
    closeSample(){
      this.sampleAddShow = false;
    },
    // 确定样品
    submitSample(){
      this.$refs['sampleForm'].validate((valid) => {
        if (valid) {
          this.$modal.loading("正在请求数据，请稍后...");
          this.sampleForm.limitValue = this.sampleForm.id ? this.sampleForm.limitValue : parseFloat('0.7');
          console.log("sampleForm",this.sampleForm)
          let data = this.sampleForm.id ? {...this.sampleForm} : {...this.sampleForm,projectId: this.projectNameForm.id};
          console.log("data",data)
          this.postApiConfig(this.apiUrl.add,data).then(()=>{
            this.$modal.closeLoading();
            this.sampleAddShow = false;
            this.$message.success("添加成功");
            this.postApiConfig(this.apiUrl.sampleList,{projectId: this.projectNameForm.id}).then((res)=>{
              this.sampleList = res.data
              this.filterSampleList = res.data
              this.$forceUpdate()
            })
          }).catch(()=>{
            this.$modal.closeLoading();
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 删除样品
    sampleDelete(){
      this.$confirm('此操作将删除该 “ ' + this.sampleForm.sampleName + ' ”， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postApiConfig(this.apiUrl.delete, {id: this.sampleForm.id}).then((res) => {
          if (res.code == 200) {
            this.$message.success('删除成功!');
            // 获取设备列表
            this.getList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    // 格式化
    formatNumber(num) {
      // 创建一个映射对象，用于存储数字到中文的对应关系
      const chineseNums = {
        1: '一',
        2: '双',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '七',
        8: '八',
        9: '九'
      };

      // 检查输入数字是否在映射范围内
      if (chineseNums.hasOwnProperty(num)) {
        return chineseNums[num];
      } else {
        // 如果数字不在范围内，可以处理这种情况，比如返回原数字或特定提示
        return num;
      }
    },
    // 获取当前时间字符串(时分秒)
    getCurrentTimeString() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    },
    // 更新联卡项目
    updateCardProject(){
      if(this.projectNameForm.cardType > 1){
        this.cardResult = [...this.projectNameForm.detectionProjects]
      }else{
        this.cardResult = [{
          id: this.projectNameForm.id,
          projectName: this.projectNameForm.projectName,
          project: this.projectNameForm.project,
          projectType: this.projectNameForm.projectType,
          settingValue: this.projectNameForm.settingValue,
          machineId: this.projectNameForm.machineId,
          detectionMethod: this.projectNameForm.detectionMethod,
          code: this.projectNameForm.code,
          cardType: this.projectNameForm.cardType,
        }]
      }
    },
    changeDetails() {
      // 首先确保有样品被选中
      if (this.sampleIndex === undefined || this.sampleIndex === '') {
        this.$message.error('请选择样品');
        this.displayDetails = null;
        return false;
      }
      this.displayDetails = !this.displayDetails;
      // 处理详情展示或取消逻辑
      if (this.displayDetails) {
        // 打开抽屉并设置标题
        this.drawerShow = true;
        this.drawerTitle = `${this.filterSampleList[this.sampleIndex].sampleName} - 详情数据`;
        if(this.isFormEmpty(this.drawerForm)){
          this.$nextTick(() => {
            this.$refs['drawerForm'].resetFields();
          })
        }
      } else {
        this.displayDetails = true
        // 使用Promise简化确认对话框的处理逻辑
        this.handleCancelConfirmation();
      }
    },
    handleCancelConfirmation() {
      this.$confirm('此操作将取消 “ ' + this.filterSampleList[this.sampleIndex].sampleName + ' ” - 样品的详情数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确认取消后的处理，如果有必要的话
        this.$message({
          type: 'success',
          message: '已取消 ' + this.filterSampleList[this.sampleIndex].sampleName + ' 详情数据'
        });
        this.drawerForm = {
          cargoSource: '',
          cargoQuantity: '',
          cargoSampleQuantity: '',
          samplerUser: '',
          cargoOwner: '',
          reagentProduction: '',
          remark: '',
        };
        this.displayDetails = false;
        this.detailedStatus = false;
        this.$refs['drawerForm'].resetFields();
        // 这里可以添加更多取消后的逻辑
      }).catch(() => {
        this.displayDetails = true
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    isFormEmpty(formObject) {
      // 遍历对象的每个属性
      for (let key in formObject) {
        if (formObject.hasOwnProperty(key)) { // 确保只检查对象自身的属性，不包括原型链上的
          const value = formObject[key];
          // 检查属性值是否为空字符串、null或undefined
          if (value === null || value === undefined || value === '') {
            return true; // 只要有一个属性为空，即返回true表示表单“为空”
          }
        }
      }
      return false; // 所有属性都不为空，则返回false
    },
    drawerClose(){
      console.log(111,this.isFormEmpty(this.drawerForm))
      if(this.isFormEmpty(this.drawerForm)){
        this.$confirm('你还未填写 “ ' + this.filterSampleList[this.sampleIndex].sampleName + ' ” - 样品的详细数据，确认关闭？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(5555)
          this.drawerForm = {
            cargoSource: '',
            cargoQuantity: '',
            cargoSampleQuantity: '',
            samplerUser: '',
            cargoOwner: '',
            reagentProduction: '',
            remark: '',
          };
          this.drawerShow = false;
          this.displayDetails = false;
          this.detailedStatus = false;
        })
      }else{
        this.drawerShow = false;
      }
    },
    cancelDrawer(){
      if(this.isFormEmpty(this.drawerForm)){
        this.$confirm('你还未填写 “ ' + this.filterSampleList[this.sampleIndex].sampleName + ' ” - 样品的详细数据，确认关闭？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(5555)
          this.drawerForm = {
            cargoSource: '',
            cargoQuantity: '',
            cargoSampleQuantity: '',
            samplerUser: '',
            cargoOwner: '',
            reagentProduction: '',
            remark: '',
          };
          this.drawerShow = false;
          this.displayDetails = false;
          this.detailedStatus = false;
        })
      }else{
        this.drawerShow = false;
      }
    },
    submitDrawer(){
      this.$refs['drawerForm'].validate((valid) => {
        if (valid) {
          console.log('submit!')
          this.drawerShow = false;
          this.detailedStatus = true;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 检测结果-格式化
    formattedBeforeRes(value) {
      if (value === '' || Number.isNaN(parseFloat(value))) {
        return '';
      }
      const num = parseFloat(value);
      if (!isNaN(num)) {
        return this.formatNumberStr(num);
      }
      return '';
    },
    formatNumberStr(value) {
      const decimalPart = value.toString().split('.')[1];
      if (decimalPart && decimalPart.length > 3) {
        return this.roundToTwoDecimals(value);
      } else {
        return value;
      }
    },
    roundToTwoDecimals(num) {
      return parseInt(num * 100)/100;
    },
    // 指定长度的数组
    createFilledArray(length, value) {
      return new Array(length).fill(value);
    },
    handleClick(item) {
      console.log("选择的哪个",item)
      if (item.specify) {
        this.sortInfo = Object.assign({},item);
        this.multipleArr = this.cardResult.map((item) => item.id)
        console.log("多选",this.multipleArr)
        this.closeBtnStatus = item.id ? false : true ;
        this.findValue = {};
        this.testItemList = this.testItemListAll;
        this.showProjectList();
      }
    },
    showProjectList() {
      this.$forceUpdate()
      this.testItemShow = !this.testItemShow;
      this.testItemIndex = null;
    },
    choiceTestItem(item){
      console.log("禁用状态",item.disabledStr)
      if(!item.disabledStr){ // 一次多选，选择
        if(this.multipleState && !item.choiceState){
          if(this.multipleArr.length < this.setCardIndex){
            this.multipleArr.push(item.id)
            this.testItemList.forEach((itemX) => {
              this.multipleArr.forEach((itemY)=>{
                if(itemX.id === itemY){
                  itemX.choiceState = true;
                }
              })
            })
          }else{
            this.$message.warning("最多选择"+this.setCardIndex+"个项目");
          }
        }else if(item.choiceState){ // 一次多选，取消已选择的
          let find = this.testItemList.find((findItem) => findItem.id === item.id)
          this.testItemList.forEach((item) => {
            if (item.id === find.id) {
              item.choiceState = false;
            }
          })
          this.multipleArr = this.multipleArr.filter((item) => item !== find.id);
        }else { // 单选
          console.log("单选",item.id)
          this.testItemList.forEach((item) => { item.choiceState = false })
          let find = this.testItemList.find((findItem) => findItem.id === item.id)
          this.testItemList.forEach((item) => {
            if (item.id === find.id) {
              item.choiceState = true;
              item.disabledStr = false;
            }
          })
          this.findValue = find
        }
        // 已选的卡槽，重新选择，重置之前的状态
        for(let i = 0; i < this.cardResult.length; i++) {
          if (this.cardResult[i].id && this.cardResult[i].id === this.sortInfo.id) {
            let find = this.testItemListAll.find((item) => item.id === this.sortInfo.id)
            this.testItemListAll.forEach((item) => {
              if (item.id === find.id) {
                item.disabledStr = false;
              }
            })
            this.testItemList.forEach((item) => {
              if (item.id === find.id) {
                item.disabledStr = false;
              }
            })
          }
        }
      }
      this.$forceUpdate()
    },
    closeTestItem(){
      this.testItemShow = !this.testItemShow;
      this.testItemIndex = null;
      this.multipleArr = [];
      this.multipleState = false;
      this.testItemList.forEach((item) => { item.choiceState = false; item.disabledStr = false})
      this.testItemList.forEach((itemX) => {
        this.cardResult.forEach((itemY) => {
          if(itemX.id === itemY.id){
            itemX.choiceState = true;
            itemX.disabledStr = true;
          }
        })
      });
      this.testItemSearch = '';
    },
    submitTestItem(){
      if(this.multipleState){ // 一次多选
        if(this.multipleArr.length === 0){
          this.$message.error('请选择检测项目');
          return false
        }
        this.testItemList.forEach((itemX)=>{
          this.multipleArr.forEach((itemY)=>{
            if (itemX.id === itemY){
              itemX.disabledStr = true;
              itemX.choiceState = false;
            }
          })
        })
        let filterArr = this.testItemList.filter((item) => item.disabledStr === true);

        this.multipleArr.forEach((itemX,indexX)=>{
          filterArr.forEach((itemY)=>{
            if(itemX === itemY.id && itemY.disabledStr === true){
              let obj = {
                id: itemY.id,
                machineId: itemY.machineId,
                projectName: itemY.projectName,
                projectType: itemY.projectType,
                settingValue: itemY.settingValue,
                cardType: itemY.cardType,
                code: null,
                detectionMethod: itemY.detectionMethod,
                sort: null,
                tripleProject: null,
                authType: itemY.authType,
                projectList: null,
                methods: null,
                sampleList: null,
                detectionProjects: null,
                inCardTypes: null,
                equipmentSn: null,
                project: itemY.project,
                specify: true, // 用于控制任意多联卡的选择项目
                sortNum: indexX + 1, // 用于校验选择的卡槽排序
              }
              this.cardResult[indexX] = Object.assign({},obj)
            }
          })
        })
        // console.log("filterArr", JSON.stringify(filterArr))
        // console.log("cardResult", JSON.stringify(this.cardResult))
      }else{ // 单次选择
        if(Object.keys(this.findValue).length === 0){
          this.$message.error('请选择检测项目');
          return false;
        }
        this.testItemList.forEach((item) => {
          if (item.id === this.findValue.id) {
            item.choiceState = false;
            item.disabledStr = true;
          }
        })
        this.testItemListAll.forEach((item) => {
          if (item.id === this.findValue.id) {
            item.choiceState = false;
            item.disabledStr = true;
          }
        })
        for(let i = 0; i < this.cardResult.length; i++){
          if(this.cardResult[i].sortNum === this.sortInfo.sortNum){
            let obj = {
              id: this.findValue.id,
              machineId: this.findValue.machineId,
              projectName: this.findValue.projectName,
              projectType: this.findValue.projectType,
              settingValue: this.findValue.settingValue,
              cardType: this.findValue.cardType,
              code: null,
              detectionMethod: this.findValue.detectionMethod,
              sort: null,
              tripleProject: null,
              authType: this.findValue.authType,
              projectList: null,
              methods: null,
              sampleList: null,
              detectionProjects: null,
              inCardTypes: null,
              equipmentSn: null,
              project: this.findValue.project,
              specify: true, // 用于控制任意多联卡的选择项目
              sortNum: this.cardResult[i].sortNum, // 用于校验选择的卡槽排序
            }
            this.cardResult[i] = Object.assign({},obj)
          }
        }
      }
      this.multipleArr = [];
      this.multipleState = false;
      this.testItemShow = !this.testItemShow;
      this.findValue = {};
      this.testItemSearch = '';
      this.testItemList = this.testItemListAll
      this.$forceUpdate();
      console.log(JSON.stringify(this.cardResult))
    },
    findEmptyIdIndex(array) {
      return array.findIndex(item => !item.id);
    },
    filterTestItem(){
      this.testItemList = this.testItemListAll.filter((e) => {
        return e.projectName.indexOf(this.testItemSearch) != -1;
      });
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    newPrepareUploadPayload() {
      this.uploadForm = Object.assign({},this.form)
      const batchSn = this.getTimeRandom();

      let projectList = this.cardResult.map(item => ({
        ocrImage: '',
        grayImage: '',
        cValue: 0,
        tValue: 0,
        projectName: item.projectName,
        projectId: item.id,
        result: '合格',
        resultValue: this.getRandomInt(100,500) / 100,
        serialNumber: this.form.sampleNumbering
      }));

      return {
        batchSn, // 检测批次
        qrKey: new Date().getTime() || this.uploadForm.qrKey, // 二维码编号
        projectId: this.uploadForm.cardId, // 项目ID
        projectName: this.uploadForm.name, //项目名称
        sampleId: this.uploadForm.sampleId, //样品名称
        sampleName: this.uploadForm.sampleName, //样品名称
        sampleNumber: this.uploadForm.sampleNumbering, //样品编号
        sampleType: this.uploadForm.sampleType, //样品类型
        judgeName: '', // 判断名称
        detectionMethod: this.uploadForm.detectionMethod, // 检测方法 1：比色法，2：消线法
        checkTime: this.systemForm.originalTime + ' ' + this.getCurrentTimeString(), // 检测时间
        projectList: projectList, // 检测数据详情
        inspectedUnit: this.uploadForm.unit, // 检测单位
        channel: 2, // 1：app，2：pc
      };
    },
    async manualUploadText(){

      console.log("cardResult",this.cardResult)

      const uploadPayloadText = this.newPrepareUploadPayload();

      console.log("uploadPayloadText",uploadPayloadText)

      this.$modal.loading("正在上传至省平台，请稍候...");
      const uploadPayload = this.newPrepareUploadPayload();
      const uploadResponse = await this.postApiConfig(this.apiUrl.upload, uploadPayload);

      // 关闭加载提示
      this.$modal.closeLoading();

      if (parseInt(uploadResponse.code) === 200) {
        this.$message.success("上传至省平台成功")
      }else{
        this.$message.error("上传至省平台失败")
      }

      // 更新样品编号
      const hfm = this.getHfm()
      this.form.sampleNumbering = this.systemForm.checkTime ? this.systemForm.checkTime + hfm : this.getTime();
      this.uploadButtonStatus = false;
    }
  },
  beforeDestroy() {
    // this.stopStream();
  }
}
</script>

<template>
  <div class="detection-page">
    <div class="detection-parameter">
      <div class="detection-parameter-item">
        <div class="detection-parameter-item-title">
          <span>项目名称:</span>
        </div>
        <div class="detection-parameter-item-box input">
          <span>{{ form.name }}</span>
        </div>
      </div>
      <div class="detection-parameter-item">
        <div class="detection-parameter-item-title">
          <span>样品名称:</span>
        </div>
        <div class="detection-parameter-item-box sample" @click="showSampleList">
          <div class="text" :class="{ 'active-text': form.sampleName}">
            <span>{{ form.sampleName ? form.sampleName : '请选择样品' }}</span>
          </div>
          <div class="icon">
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <div class="detection-parameter-item">
        <div class="detection-parameter-item-title">
          <span>受检单位:</span>
        </div>
        <div class="detection-parameter-item-box unit">
          <el-autocomplete
              v-model="form.unit"
              :fetch-suggestions="querySearch" placeholder="请输入受检单位名称" clearable :trigger-on-focus="false" @select="handleSelect"
              size="small"
              style="width: 100%;"
          >
            <template slot-scope="{ item }">
              <el-tooltip effect="dark" :content="item.value" placement="top">
                <span>{{ item.value }}</span>
              </el-tooltip>
            </template>
          </el-autocomplete>
        </div>
      </div>
      <div class="detection-parameter-item">
        <div class="detection-parameter-item-title">
          <span>试剂卡编号:</span>
        </div>
        <div class="detection-parameter-item-box input">
          <span>{{ form.qrKey }}</span>
        </div>
      </div>
      <div class="detection-parameter-item">
        <div class="detection-parameter-item-title">
          <span>样品类型:</span>
        </div>
        <div class="detection-parameter-item-box input">
          <span>{{ form.sampleType }}</span>
        </div>
      </div>
      <div class="detection-parameter-item">
        <div class="detection-parameter-item-title">
          <span>样品编号:</span>
        </div>
        <div class="detection-parameter-item-box input">
          <span>{{ form.sampleNumbering }}</span>
        </div>
      </div>
    </div>
    <!-- 检测卡 -->
    <div class="detection-card">
      <div class="detection-card-content" v-for="(item,index) in cardResult" :key="index">
        <div class="detection-card-item">
          <div class="detection-card-item-title">
            <span>左 {{ index + 1 }} 槽:</span>
          </div>
          <div class="detection-card-item-box input" :class="{ 'specifyProject' : item.specify, 'notEmpty': item.specify && item.projectName }" @click="handleClick(item)">
            <span>{{ item.projectName ? item.projectName : '请选择检测项目'  }}</span>
          </div>
        </div>
        <div class="detection-card-item">
          <div class="detection-card-item-title" style="color: #fe0000;font-weight: bolder;">
            <span>检测结果:</span>
          </div>
          <div class="detection-card-item-box input" :class="{ 'result-num' : item.afterRes }">
            <span>{{ formattedBeforeRes(item.afterRes) }}</span>
          </div>
        </div>
        <div class="detection-card-item">
          <div class="detection-card-item-title" style="color: #fe0000;font-weight: bolder;">
            <span>检测判定:</span>
          </div>
          <div class="detection-card-item-box input"
               :class="{
             'result-adopt': item.decideOutcome === '合格',
             'result-not-passed': item.decideOutcome === '不合格',
             'result-invalid': item.decideOutcome === '无效'
            }">
            <span>{{ item.decideOutcome }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="detection-video">
      <!-- 检测方法选择 -->
<!--      <div class="detection-video-method">-->
<!--        <div class="detection-video-method-item" v-for="(item,index) in testMethodList" :key="index" @click="choiceDetectionMethod(item)" :class="{'activateItem': form.detectionMethod === item.value}">-->
<!--          <span>{{ item.label }}</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="detection-video-left">
        <div class="detection-video-left-title">
          <span style="display: inline-block;width: 19px;height: 19px;background-color: #0062C4;"></span>
          <span>
            胶体金实时检测图像
          </span>
        </div>
        <div class="detection-video-left-video">
          <video id="video" ref="videoPlayer" style="width: 100%; height: 100%; object-fit: fill;"></video>
          <!-- 隐藏的canvas元素，用于绘制视频帧 -->
          <canvas ref="canvas" style="display: none;"></canvas>
        </div>
<!--        <div class="detection-btn-left">-->
<!--          <el-button type="warning" plain @click="retestCamera" v-if="videoShow">重新检测摄像头</el-button>-->
<!--        </div>-->
      </div>
      <div class="detection-video-right">
        <div class="detection-video-right-title">
          <span style="display: inline-block;width: 19px;height: 19px;background-color: #0062C4;"></span>
          <span>
            胶体金检测图像
          </span>
        </div>
        <div class="detection-video-right-video">
          <!-- 隐藏的img元素，用于展示截图 -->
          <img v-if="imgShow" :src="'data:image/png;base64,' + imgUrl" style="width: 100%;height: 100%;object-fit: fill;" alt="胶体金检测图像">
        </div>
<!--        <div class="detection-btn-right">-->
<!--          <el-button type="success" plain :disabled="!uploadButtonStatus" @click="manualUpload">上传</el-button>-->
<!--          <el-button type="primary" plain @click="detectionBtn">开始检测</el-button>-->
<!--        </div>-->
      </div>
    </div>
    <!--  -->
    <div class="btnBox">
      <div class="resetBox">
        <el-button type="warning" plain @click="retestCamera" v-if="videoShow">重新检测摄像头</el-button>
      </div>
      <div class="detectionMethod">
        <el-radio-group v-model="form.detectionMethod" style="height: 2.5rem  /* 40/16 */;display: flex;justify-content: center;align-items: center" @change="changeRadio">
          <el-radio v-for="(item,index) in testMethodList" :key="index" :label="item.value" >{{ item.label }}</el-radio>
        </el-radio-group>
      </div>
      <el-button type="primary" plain @click="detectionBtn">开始检测</el-button>
<!--      <el-button type="primary" plain @click="manualUploadText">上传(测试)</el-button>-->
      <el-button type="success" plain :disabled="!uploadButtonStatus" @click="manualUpload">上传</el-button>
    </div>
    <!-- 样品弹窗 -->
    <div>
      <el-dialog :title="projectNameForm.projectName + ' - 样品'" :visible.sync="sampleShow" width="750px" :before-close="handleClose">
        <div class="sample-search">
          <el-row>
            <el-col :span="16">
              <el-input v-model="sampleSearch" @input="inputSample" placeholder="请输入样品名称" clearable></el-input>
            </el-col>
            <el-col :span="8" style="display: flex;justify-content: flex-end;align-content: center;">
              <el-button icon="el-icon-plus" @click="addSample">添加样品</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="sample-content" v-if="sampleList.length > 0">
          <div class="sample-item"  v-for="(item,index) in filterSampleList" :key="index"  @click="choiceSample(index,item)" :class="{ 'activate-sample-item': sampleIndex === index}">
            <div>
              <span>{{ item.sampleName }}</span>
            </div>
            <div v-if="false">
              <span>{{ item.limitValue }} (mg/kg)</span>
            </div>
            <div class="lock" v-if="item.machineId == 0">
              <i class="el-icon-lock"></i>
            </div>
          </div>
        </div>
        <div style="width: 100%;height: 40vh;display: flex;justify-content: center;align-items: center;" v-else>
          <el-empty description="该检测项目 “ 无样品 ” ，请联系管理员添加"></el-empty>
        </div>

        <el-row>
          <el-col :span="8">
            <div style="width: 100%;display: flex;justify-content: flex-start;align-items: flex-start;">
              <div style="display: flex;justify-content: flex-start;align-items: center;gap: 0 5px;cursor:pointer;" @click="changeDetails">
                <div style="width: 15px;height: 15px;box-sizing: border-box;border-radius: 2px;">
                  <img src="@/assets/img/icon/select.png" alt="" style="width: 100%;height: 100%;" v-if="displayDetails">
                  <img src="@/assets/img/icon/default.png" alt="" style="width: 100%;height: 100%;" v-else>
                </div>
                <span :style="{color:displayDetails? '#409eff':''}">详细数据</span>
              </div>
              <span style="font-size: 14px;display: flex;justify-content: flex-start;align-items: flex-start;" v-if="detailedStatus">，<el-link type="success" @click="drawerShow = true">查看详细数据</el-link></span>
            </div>
          </el-col>
          <el-col :span="16">
            <div style="display: flex;justify-content: flex-end;align-content: center;">
              <el-button type="danger" plain @click="sampleDelete" v-if="editShow && false">删除</el-button>
              <el-button type="warning" plain @click="openSample" v-if="editShow">修改</el-button>
              <el-button @click="handleClose">取 消</el-button>
              <el-button type="primary" @click="confirmSample">确 定</el-button>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
    <!-- 添加样品 -->
    <el-dialog title="添加 - 检测样品" :visible.sync="sampleAddShow" width="600px" :before-close="closeSample">
      <el-form :rules="sampleRules" ref="sampleForm" :model="sampleForm" label-width="100px">
        <el-form-item label="类型名称" prop="type">
          <el-select v-model="sampleForm.type" clearable placeholder="请选择" size="small" style="width: 100%;">
            <el-option v-for="dict in beastSamples" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictLabel"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="样品名称" prop="sampleName">
          <el-input v-model="sampleForm.sampleName" clearable placeholder="请输入样品名称" size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeSample">取 消</el-button>
        <el-button type="primary" @click="submitSample">确 定</el-button>
      </span>
    </el-dialog>
    <!--  详情数据  -->
    <el-dialog :title="drawerTitle" :visible.sync="drawerShow" width="600px" :before-close="drawerClose">
     <div>
       <el-form :model="drawerForm" ref="drawerForm" :rules="drawerRules" label-width="120px">
         <el-form-item label="生猪来源" prop="cargoSource">
           <el-input v-model="drawerForm.cargoSource" placeholder="生猪来源"></el-input>
         </el-form-item>
         <el-form-item label="生猪头数" prop="cargoQuantity">
           <el-input v-model="drawerForm.cargoQuantity" placeholder="生猪头数"></el-input>
         </el-form-item>
         <el-form-item label="抽样头数" prop="cargoSampleQuantity">
           <el-input v-model="drawerForm.cargoSampleQuantity" placeholder="抽样头数"></el-input>
         </el-form-item>
         <el-form-item label="抽样人员" prop="samplerUser">
           <el-input v-model="drawerForm.samplerUser" placeholder="抽样人员"></el-input>
         </el-form-item>
         <el-form-item label="畜（货）主" prop="cargoOwner">
           <el-input v-model="drawerForm.cargoOwner" placeholder="畜（货）主"></el-input>
         </el-form-item>
         <el-form-item label="试剂生产企业" prop="reagentProduction">
           <el-input v-model="drawerForm.reagentProduction" placeholder="试剂生产企业"></el-input>
         </el-form-item>
         <el-form-item label="备注" prop="">
           <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="drawerForm.remark"></el-input>
         </el-form-item>
       </el-form>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDrawer">取 消</el-button>
        <el-button type="primary" @click="submitDrawer">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 任意多联卡选择检车项目 -->
    <el-dialog title="选择检测项目" :visible.sync="testItemShow" width="600px" :before-close="closeTestItem">
      <div class="test-item-search" style="margin-bottom: 20px">
        <el-input v-model="testItemSearch" @input="filterTestItem" clearable placeholder="请输入检测项目"></el-input>
      </div>
      <div class="test-item-content">
        <!-- 在这里放置你的列内容，例如： -->
        <div class="test-item" v-for="(item,index) in testItemList" :key="index" @click="choiceTestItem(item,index)" :class=" {'activateItem': item.choiceState, 'disabled': item.disabledStr} ">
          <!--          :class=" {'activateItem': testItemIndex === index, 'disabled': item.disabledStr} "-->
          <span>{{ item.projectName }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTestItem" v-if="closeBtnStatus">取 消</el-button>
        <el-button type="primary" @click="submitTestItem">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
@import "./detection.css";
.detection-page{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem  /* 5/16 */ 0;

  .detection-parameter{
    width: 100%;
    padding: 0.3125rem  /* 5/16 */;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.625rem  /* 10/16 */ 0;

    .detection-parameter-item{
      width: 30%;
      display: flex;
      //flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 0.4375rem  /* 7/16 */ 0;

      .detection-parameter-item-title{
        font-weight: bolder;
        font-size: 0.8125rem  /* 13/16 */;
        color: #000000;
        width: 5rem  /* 80/16 */;
        height: 1.875rem  /* 30/16 */;
        display: flex;
        align-items: center;
      }

      .detection-parameter-item-box{
        //width: 100%;
        flex: 1;
        height: 1.875rem  /* 30/16 */;
        border-radius: 0.3125rem  /* 5/16 */;
        font-weight: 400;
        font-size: 0.8125rem  /* 13/16 */;
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 0 10px;
        cursor:pointer;
      }

      .input{
        border: 1px solid #E4E7ED;
        background-color: #F5F7FA;
        color: #C0C4D5;
      }

      .sample{
        border: 1px solid #D3D3D3;
        background-color: #ffffff;
        color: #C0C4D5;
        border-radius: 0.3125rem  /* 5/16 */;
        padding: 0 10px;

        .text{
          flex: 1;
          text-align: center;
        }
        .active-text{
          color: #333333;
        }
        .icon{
          width: 22px;
          height: 12px;
          display: flex;
        }
      }
      .value{
        border: 1px solid #D3D3D3;
        background-color: #F5F7FA;
        color: #333333;
        border-radius: 0.3125rem  /* 5/16 */;
        padding: 0 10px;
        .text{
          flex: 1;
          text-align: center;
        }
        .tipStr{
          width: 3.75rem  /* 60/16 */;
          text-align: center;
        }
      }

      .radio{
        border: none;
        background-color: #ffffff;
      }
    }
  }

  .detection-card{
    width: 100%;
    height: 10rem  /* 160/16 */;
    padding: 0 0.3125rem  /* 5/16 */;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4375rem  /* 7/16 */ 0;

    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px; /* 宽度 */
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1); /* 轨道颜色 */
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.4); /* 滑块颜色 */
      border-radius: 4px; /* 圆角 */
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.6); /* 鼠标悬停时滑块颜色 */
    }

    .detection-card-content{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.4375rem  /* 7/16 */ 0;

      .detection-card-item{
        width: 30%;
        display: flex;
        //flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 0.4375rem  /* 7/16 */ 0;

        .detection-card-item-title{
          font-weight: bolder;
          font-size: 0.8125rem  /* 13/16 */;
          color: #000000;
          width: 5rem  /* 80/16 */;
          height: 1.875rem  /* 30/16 */;
          display: flex;
          align-items: center;
        }

        .detection-card-item-box{
          //width: 100%;
          flex: 1;
          height: 1.875rem  /* 30/16 */;
          border-radius: 0.3125rem  /* 5/16 */;
          font-weight: bolder;
          font-size: 0.8125rem  /* 13/16 */;
          display: flex;
          justify-content: space-around;
          align-items: center;
          gap: 0 10px;
          cursor:pointer;
        }

        .input{
          border: 1px solid #E4E7ED;
          background-color: #F5F7FA;
          color: #C0C4D5;

        }

        .specifyProject{
          border: 1px solid #E4E7ED;
          background-color: #FFFFFF;
          color: #C0C4D5;
        }

        .notEmpty{
          color: #333333;
        }

        .result-num{
          color: #333333;
          background-color: #FFFFFF;
        }

        .result-adopt{
          color: #67c23a;
          background-color: #FFFFFF;
        }
        .result-not-passed{
          color: #fe0000;
          background-color: #FFFFFF;
        }
        .result-invalid{
          color: #C0C4D5;
          background-color: #FFFFFF;
        }
      }
    }
  }

  .detection-video{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0 0.625rem  /* 10/16 */;

    .detection-video-method{
      width: 9.375rem  /* 150/16 */;
      height: 100%;
      padding: 0.3125rem  /* 5/16 */;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.3125rem  /* 5/16 */ 0;
      /* 添加这一行以允许仅Y轴滚动 */
      overflow-y: auto;

      /* 下面是自定义滚动条样式，主要针对Webkit内核浏览器 */
      scrollbar-width: thin; /* Firefox */
      //scrollbar-color: rgba(0, 0, 0, 0.4) rgba(255, 255, 255, 0.2); /* Firefox */

      &::-webkit-scrollbar {
        width: 8px; /* 宽度 */
      }

      &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1); /* 轨道颜色 */
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4); /* 滑块颜色 */
        border-radius: 4px; /* 圆角 */
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.6); /* 鼠标悬停时滑块颜色 */
      }


      .detection-video-method-item{
        width: 100%;
        height: 2.5rem  /* 40/16 */;
        box-sizing: border-box;
        border-radius: 0.625rem  /* 10/16 */;
        background-color: #fcfcfc;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        text-align: center;
        line-height: 2.5rem  /* 40/16 */;
        font-weight: 400;
        font-size: 0.875rem  /* 14/16 */;
        color: #333333;
        cursor: pointer;
        transition: transform 0.3s ease; /* 0.3秒为过渡持续时间，ease为过渡速度曲线 */

        &:hover{
          transform: translateY(-0.1875rem  /* -3/16 */);
        }
      }
      .activateItem{
        background-color: #eef6ff;
        color: #0062c4;
        border: 1px solid #0062c4;
      }
    }

    .detection-video-left{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.625rem  /* 10/16 */ 0;

      .detection-video-left-title{
        display: flex;
        align-items: center;
        gap: 0 0.625rem  /* 10/16 */;
        font-weight: 400;
        font-size: 1.125rem  /* 18/16 */;
        color: #666666;
      }
      .detection-video-left-video{
        //width: 360px;
        //height: 360px;
        width: 22.5rem  /* 360/16 */;
        height: 22.5rem  /* 360/16 */;
        background: #000000;
        display: flex;
        justify-content: center;
        align-items: center;

        #video{
          transform: rotate(-90deg);
        }
      }
      .detection-btn-left{
        width: 22.5rem  /* 360/16 */;
        display: flex;
        justify-content: center;
      }
    }
    .detection-video-right{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.625rem  /* 10/16 */ 0;

      .detection-video-right-title{
        display: flex;
        align-items: center;
        gap: 0 0.625rem  /* 10/16 */;
        font-weight: 400;
        font-size: 1.125rem  /* 18/16 */;
        color: #666666;
      }
      .detection-video-right-video{
        //width: 360px;
        //height: 360px;
        width: 22.5rem  /* 360/16 */;
        height: 22.5rem  /* 360/16 */;
        background: #000000;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          transform: rotate(-90deg);
        }
      }
      .detection-btn-right{
        width: 22.5rem  /* 360/16 */;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 3.5rem  /* 56/16 */;
      }
    }
  }

  .btnBox{
    margin-top: 0.625rem  /* 10/16 */;
    //width: 90%;
    width: 52.5rem  /* 840/16 */;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .resetBox{
      width: 140px;
    }

    .detectionMethod{
      width: 18.75rem  /* 300/16 */;
      height: 2.5rem  /* 40/16 */;
    }
  }

  .sample-search{
    width: 100%;
    margin-bottom: 10px;
  }


  .sample-content{
    width: 100%;
    height: 30vh;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 10px 9px;

    /* 添加这一行以允许仅Y轴滚动 */
    overflow-y: auto;

    /* 下面是自定义滚动条样式，主要针对Webkit内核浏览器 */
    scrollbar-width: thin; /* Firefox */
    //scrollbar-color: rgba(0, 0, 0, 0.4) rgba(255, 255, 255, 0.2); /* Firefox */

    &::-webkit-scrollbar {
      width: 8px; /* 宽度 */
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1); /* 轨道颜色 */
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.4); /* 滑块颜色 */
      border-radius: 4px; /* 圆角 */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.6); /* 鼠标悬停时滑块颜色 */
    }

    .sample-item{
      width: 24%;
      height: 40px;
      border-radius: 0.625rem  /* 10/16 */;
      box-sizing: border-box;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor:pointer;
      font-weight: 400;
      font-size: 16px;
      transition: transform 0.3s ease; /* 0.3秒为过渡持续时间，ease为过渡速度曲线 */
      position: relative;

      &:hover{
        transform: translateY(-0.1875rem  /* -3/16 */);
      }

      .lock{
        position: absolute;
        top: 4px;
        right: 8px;
        color: #999999;
      }
    }
    .activate-sample-item{
      background-color: #eef6ff;
      color: #0062c4;
      border: 1px solid #0062c4;
    }
  }

  .drawer-content{
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px 0;

    .drawer-content-title{
      width: 100%;
      height: 40px;
      font-size: 20px;
      font-weight: bolder;
    }

    .drawer-content-main{
      width: 100%;
      flex: 1;
    }
    .drawer-content-footer{
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }

  .test-item-content{
    width: 100%;
    height: 40vh;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 10px 1.375rem  /* 22/16 */;
    /* 添加这一行以允许仅Y轴滚动 */
    overflow-y: auto;
    scrollbar-width: thin; /* Firefox */
    &::-webkit-scrollbar {
      width: 8px; /* 宽度 */
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1); /* 轨道颜色 */
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.4); /* 滑块颜色 */
      border-radius: 4px; /* 圆角 */
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.6); /* 鼠标悬停时滑块颜色 */
    }

    .test-item{
      width: 165px;
      height: 3.125rem  /* 50/16 */;
      border-radius: 0.625rem  /* 10/16 */;
      box-sizing: border-box;
      background-color: #fcfcfc;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      text-align: center;
      line-height: 3.125rem  /* 50/16 */;
      overflow:hidden; //超出的文本隐藏
      text-overflow:ellipsis; //溢出用省略号显示
      white-space:nowrap; //溢出不换行
      font-weight: 400;
      font-size: 1.125rem  /* 18/16 */;
      color: #333333;
      cursor:pointer;
      transition: transform 0.3s ease; /* 0.3秒为过渡持续时间，ease为过渡速度曲线 */

      &:hover{
        transform: translateY(-0.1875rem  /* -3/16 */);
      }
    }

    .activateItem{
      background-color: #eef6ff;
      color: #0062c4;
      border: 1px solid #0062c4;
    }

    .disabled{
      color: #e8e8e8 !important;
      &:hover{
        cursor: not-allowed !important;
      }
    }
  }

}
</style>
